.section-video-gallery {
  &__video {
    width: 100%;
    padding: 92px 16px 16px 16px;
    .video-gallery {
      position: relative;
    }
  }
  .video-player-wrapper {
    box-shadow: 0px 0px 13px 4px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    position: relative;
    display: flex;
    justify-content: center;

  }
  .video-player-header {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 10px;
    width: 100%;
    z-index: 2;
    pointer-events: none;
    margin-top: -50px;

    .video-counter {
      font-size: 14px;
      color: #fff;
      background: rgba(0, 0, 0, 0.3);
      padding: 5px 10px;
      border-radius: 5px;
      pointer-events: auto;
      line-height: 20px;
    }
    .fullscreen-button {
      background: rgba(0, 0, 0, 0.3);
      border: none;
      color: #fff;
      font-size: 18px;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      pointer-events: auto;
    }
  }

  .react-player-wrapper {
    width: 100%;
    aspect-ratio: 16 / 9;
  }

  .video-player-wrapper .react-player {
    flex: 1;
    max-width: 100%;
    min-height: 300px;
  }

  .vid-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }

  iframe {
    min-height: 400px;
  }

  /* Swiper Section */
  .swiper-section {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
      padding: 0 100px;
    }
  }

  .swiper-wrapper {
    padding: 5px;
  }

  .swiper-container-videos .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    position: relative;
    top: 0px;
  }
  .swiper-container-videos .swiper-slide.vid-item {
    margin-right: 5px !important;
  }

  .swiper-container-videos .swiper-slide button {
    text-decoration: none;
    font-size: 14px;
    color: $dfb-color-green-primary;
    background-color: #fff;
    padding: 5px;
    border: 1px white solid;
    border-radius: 6px;
  }

  .swiper-container-videos .selected {
    box-shadow: 0px 0px 3px 3px rgba(49, 190, 115, 0.419);
  }

  .swiper-container-videos .swiper-slide {
    width: 46%;
    text-align: center;
    font-size: 18px;
    background: #fff;
    @media screen and (min-width: 768px) {
      width: 26%;

    }
  }

  .swiper-container-videos .swiper-slide button .desc {
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .swiper-container-videos .swiper-slide img {
    height: auto;
    width: 100%;
  }

  .swiper-pagination-bullet-active {
    background: #b4234c;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  /* Custom Navigation Buttons */
  .swiper-custom-prev,
  .swiper-custom-next {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    cursor: pointer;
    background: $dfb-color-green-primary;
    border: none;
    border-radius: 50%;
    width: 44px;
    min-width: 44px;
    height: 44px;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .swiper-custom-prev {
    margin-right: 15px;
    color: $dfb-color-green-primary;
  }

  .swiper-custom-next {
    margin-left: 15px;
    color: $dfb-color-green-primary;
    svg {
      transform: rotate(180deg)
    }
  }

  .vid-thumb {
    overflow: hidden;
    display: block;
    width: auto;
  }

  .vid-thumb img {
    display: block;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }

  .vid-thumb:hover img {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
    cursor: pointer;
  }

  .swiper-slide:hover .desc {
    margin: 0px;
    padding: 0px;
    text-decoration: underline;
  }
  .section-head__text {
    ul {
      margin-left: 1rem;
      list-style: disc;
    }
  }
}
