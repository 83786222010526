@mixin anim-transition {
  transition: all 0.5s cubic-bezier(0.09, 0.01, 0, 0.98) 0s;
}

@mixin anim-transition-delay {
  transition: all 0.5s cubic-bezier(0.09, 0.01, 0, 0.98) 0.5s;
}

.section-head {
  &__title {
    text-transform: uppercase;
    font-size: 48px;
    letter-spacing: 1.75px;
    -webkit-text-fill-color: $dfb-color-white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $dfb-color-grey5;
    opacity: 0.2;
    white-space: nowrap;
    -webkit-text-rendering: optimizeSpeed;
    text-rendering: optimizeSpeed;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    &--anim {
      &-half {
        transform: translateX(-5%);
      }

      &-letter {
        transform: translateX(-5%);
      }
    }

    @include dfb-respond-to(m) {
      font-size: 86px;
      letter-spacing: 18px;
      -webkit-text-stroke-width: 3px;
      -webkit-text-stroke-color: $dfb-color-grey6;
      opacity: 0.1;
      line-height: 110px;

      &--anim {
        &-half {
          transform: translateX(-80%);
        }

        &-letter {
          transform: translateX(-14%);
        }
      }
    }

    @include dfb-respond-to(l) {
      font-size: 130px;
      letter-spacing: 25px;
      -webkit-text-stroke-width: 4px;
      -webkit-text-stroke-color: $dfb-color-grey6;
      line-height: 156px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: -1.1rem;

    & > div button {
      max-width: 200px;
      margin-bottom: 1rem;

      @include dfb-respond-to(m) {
        display: none;
      }
    }

    @include dfb-respond-to(s) {
      margin-top: -1.3rem;
    }

    @include dfb-respond-to(m) {
      margin-top: -3rem;
    }

    @include dfb-respond-to(l) {
      margin-top: -3.5rem;
    }

    &--extraWidth {
      @include dfb-respond-to(m) {
        width: 130%;
      }
    }
  }

  &__subtitle {
    color: $dfb-color-black;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 2px;
    margin-bottom: 1rem;
    width: 70%;
    text-transform: uppercase;
    -webkit-text-rendering: optimizeSpeed;
    text-rendering: optimizeSpeed;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    @include dfb-respond-to(m) {
      font-size: 56px;
      margin-bottom: 2rem;
      line-height: 62px;
      letter-spacing: 4px;
      width: 100%;
    }
  }

  &__text {
    display: block;
    color: $dfb-color-webblack;
    font-size: 18px;
    line-height: 28px;
    -webkit-text-rendering: optimizeSpeed;
    text-rendering: optimizeSpeed;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    @include dfb-respond-to(s) {
      width: 70%;
    }

    @include dfb-respond-to(m) {
      width: 100%;
    }

    &:not(:first-of-type) {
      padding-top: 0.7rem;
    }

    &--hidden {
      display: none;
    }
  }

  &__extended-text {
    padding-top: 0.7rem;
  }

  &__more {
    display: flex;
    grid-gap: 12px;
    color: $dfb-color-success;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;
    padding-top: 1rem;

    @include dfb-respond-to(m) {
      padding-top: 2rem;
    }
  }
}
