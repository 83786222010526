.modal {
  &__header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;

    &--logo {
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;

      img{
        width: 48px;
        height: 48px;
        margin-right: $dfb-spacing-m;
      }
    }

    &--dfb {
      width: 100px;

      @include dfb-respond-to(s) {
        width: 100%;
      }
    }

    &--anstoss {
      width: 110px;
      height: 50px;
      background:  center / contain no-repeat url("../../images/logo-anstossgruen.png") ;

      &-white {
        background-image: url("../../images/logo-anstossgruen-white.png");
      }

      &-black {
        background-image: url("../../images/logo-anstossgruen.png");
      }
    }
  }
  &__bubble {
    background: url("../../images/dfb-share.svg") no-repeat center center;
    height: 400px;
    width: 430px;
  }
}


