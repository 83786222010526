.footer {
  &__container {
    background-color: $dfb-color-grey1;
    width: 100%;
    margin: 0 auto;
  }
  &__info {
    display: flex;
    flex-direction: column;
    padding: 3rem 4.125%;
    gap: $dfb-spacing-rem-xxl;
    margin-bottom: (-$dfb-spacing-rem-xxl);

    @include dfb-respond-to(l) {
      flex-direction: row;
      margin-bottom: 0;
      padding: 3rem 5%;
    }
  }

  &__copyright {
    color: $dfb-color-white;
    opacity: 0.6;
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
  }

  &__general {
    background-color: #006359;
    width: 100%;

    &--wrapper {
      padding-top: $dfb-spacing-rem-l * 1.25;
      padding-bottom: $dfb-spacing-rem-l * 1.25;
      display: flex;
      flex-direction: column;
      color: $dfb-color-white;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 14px;

      @include dfb-respond-to(l) {
        flex-direction: row;
      }
    }
    &--left {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include dfb-respond-to(l) {
        width: 20%;
      }
    }
    &--center {
      @include dfb-respond-to(l) {
        width: 60%;
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include dfb-respond-to(xl) {
        gap: $dfb-spacing-l;
        flex-direction: row;
      }

      @include dfb-respond-to(l) {
        width: 20%;
        gap: $dfb-spacing-l;
        align-items: flex-start;
      }
    }
  }
  &__gutes-spiel {
    text-align: center;

    @include dfb-respond-to(s) {
      text-align: left;
    }

    img {
      margin-bottom: $dfb-spacing-l;
      width: 160px;

      @include dfb-respond-to(l) {
        margin-top: 0;
      }
      @include dfb-respond-to(xl) {
        width: 177px;
      }
    }
  }

  &__funding {
    text-align: center;

    @include dfb-respond-to(s) {
      text-align: left;
    }

    p {
      font-weight: 400;
      text-transform: capitalize;
      display: none;

      @include dfb-respond-to(l) {
        display: block;
      }
    }
    img {
      margin-top: $dfb-spacing-l;
      width: 275px;

      @include dfb-respond-to(l) {
        margin-top: 0;
      }

      @include dfb-respond-to(xl) {
        width: 272px;
      }
    }
  }

  &__title {
    margin-bottom: $dfb-spacing-m;
    letter-spacing: auto;
  }

  &__content {
    span {
      color: $dfb-color-white;
    }
    &--imprint {
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: $dfb-spacing-rem-s;

      a {
        width: 50%;

        @include dfb-respond-to(l) {
          width: fit-content;
        }
        span {
          font-weight: 400;
          text-transform: capitalize;
          margin-right: $dfb-spacing-m;
          letter-spacing: 0px;
        }
      }
    }
    svg {
      fill: $dfb-color-white;
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
    svg:hover {
      fill: $dfb-color-success;
    }
    &--social {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-bottom: $dfb-spacing-rem-xl;

      @include dfb-respond-to(m) {
        width: 60%;
      }
    }
  }
}

a {
  width: fit-content;
}
