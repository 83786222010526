.hero {
  &__container {
    background: url('../../images/image-stage-XS.jpg') no-repeat center center;
    height: calc(70vh - 70px);
    width: 100%;
    position: relative;
    background-size: cover;
    padding: $dfb-spacing-xxs;
    margin: 0;
    overflow: hidden;

    .grid-container {
      height: 100%;

      .grid-wrapper {
        height: 100%;

        .hero__lottie {
          height: 100%;

          div {
            height: 100%;
          }
        }
      }
    }

    @include dfb-respond-to(m) {
      background: url('../../images/image-stage-XL.jpg') no-repeat center center;
      background-size: cover;
      height: calc(100vh - 70px);
    }

    @include dfb-respond-to(l) {
      padding: $dfb-spacing-xxl;
    }

    @media only screen and (min-device-width: 375px) and (max-device-width: 915px) and (orientation: landscape) {
      height: calc(100vh - 70px);
    }
  }

  &__lottie {
    //position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    //display: none;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__blob-image {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-47%, -30%) rotate(116deg);
    opacity: 0.35;
    z-index: 0;

    svg {
      width: 750px;
      height: 600px;

      @include dfb-respond-to(m) {
        width: auto;
        height: auto;
      }
    }
  }

  &__text {
    color: $dfb-color-white;
    text-transform: uppercase;
    font-weight: bold;
    z-index: 3;

    &--anim-fade {
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-out;
    }

    &--small {
      line-height: 34px;
      letter-spacing: 4px;
      margin-bottom: $dfb-spacing-s;
      padding-top: $dfb-spacing-xxl;
      text-decoration-line: underline;
      text-underline-offset: 8px;
      width: 280px;

      @include dfb-respond-to(l) {
        font-size: 24px;
      }
    }

    &--main {
      word-wrap: break-word;
      hyphens: auto;
      max-width: 380px;

      @include dfb-respond-to(s) {
        max-width: 450px;
      }
      @include dfb-respond-to(m) {
        max-width: 550px;
      }
      @include dfb-respond-to(l) {
        max-width: 650px;
      }
    }
  }

  // Button Style
  &__btn {
    display: flex;
    position: absolute;
    bottom: 35px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    width: 40px;
    height: 40px;
    @include dfb-respond-to(s) {
      width: initial;
      height: initial;
    }
  }
}

.dfb-button--icon.btn__white {
  width: 75px;
  height: 75px;
  border-color: white;

  span {
    width: 75px;
    height: 38px;

    svg {
      font-size: 40px;
      color: $dfb-color-white;
    }
  }
}
