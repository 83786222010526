.sticky-item {
  display: none;
  z-index: 100;
  cursor: pointer;

  @include dfb-respond-to(m) {
    display: block;
    position: fixed;
    right: 0;
    top: 53%;
    transform: translateY(-50%);

    &__content {
      height: 6.7rem;
      width: 6.8rem;
      background-image: url('../../ui-icons/sticky-section-inactive-bg.svg');
      background-repeat: no-repeat;
      margin: 0.5rem 0;
      display: grid;
      grid-template-rows: auto;
      justify-items: center;
      text-align: center;
      align-items: center;

      &--active {
        background-image: url('../../ui-icons/sticky-section-active-bg.svg');
        background-repeat: no-repeat;
        align-content: center;
      }
    }

    &__logo {
      display: none;

      .sticky-item__content--active & {
        display: block;
        height: 2.75rem;
        width: 2.5rem;
        padding-bottom: 0.5rem;
      }
    }

    &__title {
      color: $dfb-color-white;
      text-transform: uppercase;
      word-break: break-word;
      hyphens: auto;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1.55px;

      .sticky-item__content--active & {
        color: $dfb-color-black;
      }
    }
  }
}
