.section-spiel {
  position: relative;
  margin-top: 0;
  overflow: hidden;
  padding-top: $dfb-spacing-xxxl + $dfb-spacing-l;

  &__blob-wrapper {
    position: relative;
    overflow-x: clip;
  }

  &__blob {
    position: absolute;
    mask-image: url('../../images/blob-spielt-mit.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    transform: translate(71%, -36%) rotate(340deg);
    right: 0;
    overflow: hidden;
    width: 120%;
    pointer-events: none;

    @include dfb-respond-to(s) {
      transform: translate(71%, -39%) rotate(340deg);
    }

    @include dfb-respond-to(m) {
      transform: translate(61%, -10%);
      width: 100%;
    }

    &--image {
      transform: translate(-26%, 14%);
      overflow: hidden;

      @include dfb-respond-to(m) {
        transform: translate(-26%, 14%);
      }
    }
  }

  &__video {
    width: 100%;
    padding: $dfb-spacing-xl $dfb-spacing-s $dfb-spacing-s $dfb-spacing-s;

    @include dfb-respond-to(m) {
      padding-top: $dfb-spacing-xxl;
    }
  }
}
