@import '~@dfb/dfb-ui/src/components/steps/steps.scss';
@import '~@dfb/dfb-ui/src/components/progress/progress.scss';

.massnahmen {
  width: 100%;
  height: auto;

  &__modal .dfb-Modal-contentContainer {
    padding: 0;
  }

  &__section-card {
    display: none;

    @include dfb-respond-to(m) {
      display: flex;
    }
  }

  &__progress {
    .dfb-Progress-progress {
      height: 11px;
      background-color: $dfb-color-grey3;
      border-radius: 4px;
    }

    .dfb-Progress-bar {
      border-radius: 4px 0 0 4px;
    }
  }

  &__header {
    .dfb-Progress-bar {
      background-color: $dfb-color-success;
    }

    margin-top: $dfb-spacing-m;

    @include dfb-respond-to(m) {
      margin-bottom: $dfb-spacing-xl;
    }
  }

  &__loader {
    position: relative;
    margin-bottom: $dfb-spacing-m;
    margin-top: 11px;

    @include dfb-respond-to(m) {
      margin-bottom: $dfb-spacing-xxl;
    }

    &-knob {
      line-height: 0;
      position: absolute;
      top: 50%;
      transition: left 0.2s linear;
      z-index: 2;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.45);
    }

    &-icons {
      svg {
        width: 32px;
        height: 32px;

        @include dfb-respond-to(m) {
          width: 48px;
          height: 48px;
        }
      }

      path:first-child {
        fill: $dfb-color-black;
      }
    }
  }

  &__lotti-spark {
    width: 200px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(90deg) translate(36%, -25%);
  }

  &__status {
    align-items: end;
    padding-bottom: 10px;
    font-size: 20px;

    span {
      font-weight: bold;
      color: $dfb-color-dachmarke-lightGreen-active;
    }

    & > *:not(:first-child) {
      margin-left: $dfb-spacing-xs;
    }
  }

  &__filter {
    align-items: baseline;
    flex-wrap: wrap;
    display: none;
    margin-bottom: 1px;

    @include dfb-respond-to(m) {
      display: flex;
    }

    h5 {
      width: 100%;
      margin-bottom: 8px;
    }

    &--mobile {
      display: flex;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      background-color: white;
      margin-left: -6%;
      margin-right: -6%;
      padding: 4px 12px;
      box-shadow: 3px 4px 7px -1px rgb(0 0 0 / 10%);

      .scrolling-up & {
        top: 70px;
      }

      @include dfb-respond-to(m) {
        display: none;
      }
    }

    .dfb-Dropdown {
      width: 48%;

      &.is-open {
        .dfb-Dropdown-listbox {
          max-height: 700px;
        }
      }

      &:first-of-type {
        margin-right: 4%;
        @include dfb-respond-to(l) {
          margin-right: inherit;
        }
      }

      @include dropdown-label-font ;

      &-value {
        color: $dfb-color-grey5;
      }

      &-toggle {
        width: 12px;
        right: 8px;
      }


      @include dfb-respond-to(m) {
        width: 100%;

        &-toggle {
          width: 14px;
          right: 8px;
        }
      }

      @include dfb-respond-to(l) {
        width: 100%;
      }
    }

    ul > div:not(:last-of-type) {
      .dfb-Dropdown-item {
        border-bottom: 2px solid #bababa;
      }
    }

    @include dfb-respond-to(m) {
      flex-wrap: nowrap;
      h5 {
        width: inherit;
        margin-bottom: 0;
      }
      & > *:not(:first-child) {
        flex-wrap: inherit;
      }
    }

    @include dfb-respond-to(l) {
      & > *:not(:first-child) {
        margin-left: $dfb-spacing-l;
      }
    }
  }

  &__blob-image {
    position: absolute;
    top: 50%;
    left: 0;
    width: auto;
    height: auto;
    opacity: .2;
    display: none;

    svg {
      width: 100%;
    }

    @include dfb-respond-to(s) {
      display: block;
    }
  }

  &__masonry {
    display: flex;
    margin-left: -20px; /* gutter size offset */
    margin-top: 20px;
    width: auto;

    &-column {
      padding-left: 20px; /* gutter size */
      background-clip: padding-box;

      & > div {
        background: grey;
        margin-bottom: 20px;
      }
    }
  }

  &-card {
    cursor: pointer;
    overflow: hidden;

    .massnahmen-card__image {
      position: relative;
      z-index: 2;
      overflow: hidden;

      &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: calc(1 / 2) * 100%;
      }

      & > div {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &__read-indicator {
      width: 48px;
      height: 48px;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
      background-color: $dfb-color-dachmarke-lightGreen;
      animation: fadeInAfterRead 2s forwards;
      padding: 6px;
      display: flex;

      svg {
        width: 32px;
        height: auto;

        path {
          fill: $dfb-color-white;
        }
      }
    }

    &__read-state {
      z-index: 5;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //background-color: rgba($dfb-color-dachmarke-lightGreen, 1);
      //animation: fadeInReadFadeOut 2s forwards;
      //color: $dfb-color-white;

      //svg path {
      //  fill: $dfb-color-white;
      //}
    }

    &__category-list {
      li {
        display: inline-block;

        &:not(:first-of-type):before {
          content: '\2022';
          margin-right: 0.5em;
          margin-left: 0.5em;
        }
      }
    }
  }

  &__footer {
    position: relative;
    z-index: 4;
  }
}

.section-massnahmen {
  position: relative;

  &--more {
    &:after {
      background: #fff;
      background: linear-gradient(0deg, #fff 40%, hsla(0, 0%, 100%, 0));
      bottom: -10px;
      content: " ";
      display: block;
      height: 300px;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  .dfb-Modal-contentContainer {
    max-width: 1000px;
    padding: 4rem 1.5rem;
    @include dfb-respond-to(m) {
      padding: 4rem 3rem;
    }
  }

  .modal-trophy .dfb-Modal-contentContainer {
    width: 100%;
    padding: 0px;
    @include dfb-respond-to(m) {
      max-width: 600px;

    }
  }
  .section-head__text {
    width: 100%;
  }
}

.fadeInReadFadeOut--anim {
  animation: fadeInReadFadeOut 2s forwards;
}

@keyframes fadeInReadFadeOut {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes fadeInAfterRead {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
