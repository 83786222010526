@use 'sass:math';

.hidden {
  display: none;
}

.section {
  height: auto;
  margin-top: $dfb-spacing-xxl + $dfb-spacing-s;
  overflow-x: clip;

  @include dfb-respond-to(m) {
    margin-top: $dfb-spacing-xxxl + $dfb-spacing-l;
  }

  &--full {
    height: 100%;
    min-height: 100vh;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
}

.col {
  flex: 1 0 0%;
}

.d-block {
  display: block;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.justify-content-between {
  display: flex;
  justify-content: space-between;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.margin-lr-auto {
  margin: 0 auto;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 100%;
}

.pb-1-5 {
  padding-bottom: $dfb-spacing-rem-m;
}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

// DFB Container
.grid {
  &-container {
    @include dfb-grid-container;
  }

  &-wrapper {
    @include dfb-grid-wrapper;
  }

  &-offset-pre {
    &--one {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(1);
      }
    }

    &--two {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(2);
      }
    }

    &--three {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(3);
      }
    }

    &--four {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(4);
      }
    }

    &--five {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(5);
      }
    }

    &--six {
      @include dfb-respond-to(m) {
        @include dfb-grid-pre(6);
      }
    }
  }

  &-offset-post {
    &--one {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(1);
      }
    }

    &--two {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(2);
      }
    }

    &--three {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(3);
      }
    }

    &--four {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(4);
      }
    }

    &--five {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(5);
      }
    }

    &--six {
      @include dfb-respond-to(m) {
        @include dfb-grid-post(6);
      }
    }
  }

  &-cell--one {
    @include dfb-grid-cell(1, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, m));
    }
    @include dfb-respond-to(l) {
      @include dfb-grid-cell(1, map-get($dfb-grid-columns, l));
    }
  }

  &-cell--three {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, m));
    }
    @include dfb-respond-to(l) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, l));
    }
    @include dfb-respond-to(xl) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, xl));
    }
  }

  &-cell--four {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(2, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(4, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--five {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--half {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(6, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--seven {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(7, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--eight {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));
    @include dfb-respond-to(s) {
      @include dfb-grid-cell(4, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(8, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--ten {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(3, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(10, map-get($dfb-grid-columns, m));
    }
  }

  &-cell--twelve {
    @include dfb-grid-cell(6, map-get($dfb-grid-columns, xs));

    @include dfb-respond-to(s) {
      @include dfb-grid-cell(5, map-get($dfb-grid-columns, s));
    }
    @include dfb-respond-to(m) {
      @include dfb-grid-cell(12, map-get($dfb-grid-columns, m));
    }
    min-height: 100%;
  }
}