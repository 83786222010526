.section-vereinskasse {
  .section-head {
    &__subtitle {
      width: 100%;
      @include dfb-respond-to(m) {
        width: inherit;
      }
    }
  }

  .massnahmen__status {
    padding-left: 0;
    margin-top: 1rem;
    margin-bottom: 1.5rem;

    @include dfb-respond-to(m) {
      margin-top: 1.5rem;
      margin-bottom: 3.5rem;
    }

    span {
      font-size: 18px;

      &:last-of-type {
        color: inherit;
        font-weight: inherit;
      }
    }
  }

  &__map {
    margin-top: $dfb-spacing-xxxl;
  }

  &__results-headline {
    margin-top: 3.5rem;
  }
  .vereinskasse__section-card {
    display: none;

    @include dfb-respond-to(m) {
      display: flex;
      align-items: flex-end;
    }
  }
  &__formular {
    background-color: #C5E0D4;
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    margin-top: 1.875rem;
    @media screen and (min-width: $dfb-breakpoint-m) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &-text {
      padding-left: 1.875rem;
      display: flex;
      flex-direction: column;
      gap: .625rem;
      button {
        background-color: $dfb-color-white;
      }
    }

  }
}

.vereinskasse__modal {
  .dfb-Modal-contentContainer {
    padding: 0;
  }
}
