.modal-formular {
  &__input {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__content-title {
    margin-bottom: 2rem;
  }
  .dfb-Modal {
    &-contentContainer {
      max-width: initial;
      @media screen and (min-width: $dfb-breakpoint-m) {
        max-width: 880px;

      }
    }
    &-modalFooter {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: $dfb-breakpoint-s) {
        flex-direction: row;
      }
      button {
        width: -moz-fit-content;
        width:fit-content;
        &:first-child {
          margin-bottom: 1rem;
          @media screen and (min-width: $dfb-breakpoint-s) {
            margin-right: 1.25rem;
          }
        }
      }
    }
  }
  &__error {
    color: $dfb-color-error;
  }
}
.modal-teaser__content {
  margin-bottom: 1.875rem;
  &-title {
    margin-bottom: 1.875rem                ;

  }
}

// .frc-banner {
//   display: none;
// }
.frc-captcha {
  min-width: unset !important;
  border: none !important;
  .frc-container {
    height: 2.5rem !important;
    min-height: 2.5rem !important;
  }
  .frc-icon {
    margin: 0 !important;
    fill: $dfb-color-dachmarke-lightGreen !important;
    stroke: $dfb-color-dachmarke-lightGreen !important;
  }
  .frc-content {
    display: none;
    .frc-progress {
      color: $dfb-color-dachmarke-lightGreen !important;
    }
  }
  .frc-banner {
    display: none !important;
  }
}
