// DFB UI React
@import '~@dfb/dfb-ui-react/style/base';
@import '~@dfb/dfb-ui-react/style/theme/theme';
@import '~@dfb/dfb-ui-react/brands/dachmarke/style/base';
@import '~@dfb/dfb-ui-react/brands/akademie/style/base';
@import '~@dfb/dfb-ui-react/brands/dachmarke/style/theme/theme';

// DFB UI Components
@import '~@dfb/dfb-ui/src/components/icon/icon';
@import '~@dfb/dfb-ui/src/components/icon-button/icon-button';

// Helpers
@import 'scss/helpers/mixin';
@import 'scss/helpers/font';
@import 'scss/helpers/text';
@import 'scss/helpers/layout';
@import 'scss/helpers/colors';
@import 'scss/helpers/overrides';

// Components

// Atoms
@import 'scss/atom/burger-button';
@import 'scss/atom/download';
@import 'scss/atom/footer-info';
@import 'scss/atom/link-button';
@import 'scss/atom/loader';
@import 'scss/atom/section-card';
@import 'scss/atom/section-header';
@import 'scss/atom/video-player';
@import 'scss/atom/blob-image';
@import 'scss/atom/logo-card';
@import 'scss/atom/modal-inner-header';
@import 'scss/atom/social-media-sharing.scss';
@import 'scss/atom/multiple-dropdown.scss';

// Elements
@import 'scss/element/footer';
@import 'scss/element/sticky-section';
@import 'scss/element/germany-map';
@import 'scss/element/card-slider';
@import 'scss/element/modal-trophy';
@import 'scss/element/video-gallery';
@import 'scss/element/modal-formular';

// Modules
@import 'scss/module/header';
@import 'scss/module/hero';
@import 'scss/module/section-taktik';
@import 'scss/module/massnahmen';
@import 'scss/module/section-teamplayer';
@import 'scss/module/section-anpfiff';
@import 'scss/module/section-vereinskasse';
@import 'scss/module/section-spiel';
@import 'scss/module/card-content-modal';

// Swiper Library
@import 'swiper/css';
// @import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

// Print Preview
@media print {
  .social-media {
    display: none;
  }
}
