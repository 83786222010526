.modal-formular {
  .multiple-dropdown {

    .css-1fhf3k1-control, .css-yk16xz-control, .css-1pahdxg-control{
      height: 53px;
      min-height: 53px;
      max-height: 53px;
      border-radius: 0;
      border: 1px solid #d3d7de;
      box-shadow: none;
    }
    .css-1n7v3ny-option, .css-yt9ioa-option, .css-9gakcf-option {
      background-color: transparent;
      label {
        color: black;
      }
    }
    input[type='checkbox'] {
      accent-color: $dfb-color-germany-map-lightgreen--active;
    }
    .css-g1d714-ValueContainer{
      padding-left: 1rem;
    }
    .css-1okebmr-indicatorSeparator {
      background-color: none;
    }
  }
}