.info__link {
  color: $dfb-color-dachmarke-lightGreen;
  display: flex;
  align-items: center;
  padding-top: $dfb-spacing-xs;
  padding-bottom: $dfb-spacing-xs;

  &:first-of-type {
    padding-top: 0;
  }

  &--icon {
    fill: $dfb-color-dachmarke-lightGreen;
    margin-left: $dfb-spacing-xxxs;
  }
}

.info__link, .download__container a {
  svg {
    transition: ease-in-out 0.3s;
  }

  &:hover svg, &:focus svg {
    transform: translateY(-5px);
    transition: ease-in 0.2s;
  }
}
