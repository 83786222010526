@mixin dropdown-label-font {
  &-label {
    font-size: 18px;
    top: 11px;
    color: $dfb-color-webblack;
    @include dfb-respond-to(s) {
      font-size: 1rem;
      top: 14px;
    }
    @include dfb-respond-to(m) {
     color: $dfb-color-grey5;
    }
  }
}

@mixin social-media-sharing-buttons {
  padding: 5px;
  width: 40px;
  height: 40px;
}






